<template>
    <b-card>
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <b-form
                    class="p-2"
                    @submit.prevent="Submit"
                    @reset.prevent="resetValue"
            >

                <b-form-group
                        label="نام کاربری"
                >
                    <b-form-input
                            dir="ltr"
                            class="text-left"
                            v-model="form.email"
                            trim
                            placeholder="نام کاربری"
                    />
                </b-form-group>

                <b-form-group
                        label="رمز عبور"
                >
                    <b-row>
                        <b-col
                                cols="12"
                                md="6"
                        >
                            <b-form-input
                                    :state="form.newPassword ? form.newPassword.length>0 : true"
                                    dir="ltr"
                                    class="text-left mb-1"
                                    type="password"
                                    v-model="form.newPassword"
                                    trim
                                    placeholder="رمز عبور"
                            />
                            <b-form-invalid-feedback>
                                برای اعمال تغییرات رمز عبور الزامیست
                            </b-form-invalid-feedback>
                        </b-col>
                        <b-col
                                cols="12"
                                md="6"
                        >
                            <b-form-input
                                    :state="form.newPassword===password"
                                    dir="ltr"
                                    class="text-left"
                                    type="password"
                                    v-model="password"
                                    trim
                                    placeholder="تکرار رمز عبور"
                            />
                            <b-form-invalid-feedback v-if="form.newPassword!==password">
                                تکرار رمز اشتباه
                            </b-form-invalid-feedback>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group
                        label="اطلاعات شخصی"
                >
                    <b-row>
                        <b-col
                                cols="12"
                                md="4"
                        >
                            <b-form-input
                                    dir="ltr"
                                    class="text-left mb-1"
                                    v-model="form.firstName"
                                    trim
                                    placeholder="نام و نام خانوادگی"
                            />
                        </b-col>
                        <b-col
                                cols="12"
                                md="4"
                        >
                            <b-form-input
                                    dir="ltr"
                                    class="text-left mb-1"
                                    v-model="form.lastName"
                                    trim
                                    placeholder="نام و نام خانوادگی"
                            />
                        </b-col>
                        <b-col
                                cols="12"
                                md="4"
                        >
                            <b-form-input
                                    dir="ltr"
                                    class="text-left"
                                    v-model="form.mobileNumber"
                                    trim
                                    placeholder="تلفن همراه"
                            />
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-row>
                    <b-col
                            cols="12"
                            md="6"
                    >
                        <b-form-group
                                label="نقش"
                        >
                            <v-select
                                    v-model="form.role"
                                    dir="rtl"
                                    :key="roles.length"
                                    :options="roles"
                                    :reduce="val => val.value"
                                    :clearable="false"
                                    input-id="role"
                                    style="min-width:105px"
                                    @input="getPrivileges(form.role)"
                                    :disabled="id === undefined"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                            cols="12"
                            md="6"
                    >
                        <b-form-group
                                label="وضعیت"
                        >
                            <v-select
                                    v-model="form.isActive"
                                    dir="rtl"
                                    :key="form.isActive"
                                    :options="status"
                                    :reduce="val => val.value"
                                    :clearable="false"
                                    input-id="role"
                                    style="min-width:105px"
                                    :disabled="id === undefined"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <!-- footer -->
                <div
                        class="d-flex mt-2"
                >
                    <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                            :disabled="notValidation && $havePermission('UPDATE_ADMINS')"
                    >
                        اعمال تغییرات
                    </b-button>
                </div>
            </b-form>

            <!-- PERMISSION TABLE -->
            <b-card
                    no-body
                    class="border mt-1"
            >
                <b-card-header class="p-1">
                    <b-card-title class="font-medium-2">
                        <feather-icon icon="LockIcon" size="18"/>
                        <span class="align-middle ml-50">دسترسی ها</span>
                    </b-card-title>
                </b-card-header>
                <b-table
                        striped
                        responsive
                        class="mb-0"
                        :fields="header"
                        :items="permissionsData"
                >

                    <template #cell(module)="data">
                        {{ roleLabel(data.value) }}
                    </template>
                    <template #cell()="{item,field}">
                        <b-form-checkbox v-if="item[field.key]" :value="field.key + '_' + item.module"
                                         v-model="privileges" disabled/>
                    </template>
                </b-table>
            </b-card>
        </b-overlay>
    </b-card>
</template>

<script>

    const permissionsData = [
        {
            module: 'USERS',
            LIST: true,
            DETAILS: true,
            CREATE: false,
            UPDATE: true,
            DELETE: true,
        },
        {
            module: 'ADMINS',
            LIST: true,
            DETAILS: true,
            CREATE: true,
            UPDATE: true,
            DELETE: true,
        },
        {
            module: 'ROLES',
            LIST: true,
            DETAILS: true,
            CREATE: true,
            UPDATE: true,
            DELETE: true,
        },
        {
            module: 'DOLLARS',
            LIST: true,
            DETAILS: true,
            CREATE: true,
            UPDATE: false,
            DELETE: false,
        },
        {
            module: 'WALLET',
            LIST: true,
            DETAILS: true,
            CREATE: true,
            UPDATE: true,
            DELETE: false,
        },
        {
            module: 'WITHDRAW',
            LIST: true,
            DETAILS: true,
            CREATE: false,
            UPDATE: true,
            DELETE: true,
        },
        {
            module: 'HISTORIES',
            LIST: true,
            DETAILS: true,
            CREATE: false,
            UPDATE: false,
            DELETE: false,
        },
        {
            module: 'TRANSACTIONS',
            LIST: true,
            DETAILS: false,
            CREATE: true,
            UPDATE: false,
            DELETE: false,
        },
        {
            module: 'FAQ',
            LIST: true,
            DETAILS: false,
            CREATE: true,
            UPDATE: true,
            DELETE: true,
        },
        {
            module: 'USER_MANUAL',
            LIST: true,
            DETAILS: false,
            CREATE: true,
            UPDATE: true,
            DELETE: true,
        },
        {
            module: 'EXCHANGE_INFO',
            LIST: false,
            DETAILS: false,
            CREATE: false,
            UPDATE: true,
            DELETE: false,
        },
        {
            module: 'TRADES',
            LIST: true,
            DETAILS: true,
            CREATE: false,
            UPDATE: true,
            DELETE: false,
        },
        {
            module: 'ORDERS',
            LIST: true,
            DETAILS: false,
            CREATE: true,
            UPDATE: true,
            DELETE: true,
        },
        {
            module: 'MARKET',
            LIST: true,
            DETAILS: true,
            CREATE: true,
            UPDATE: true,
            DELETE: true,
        },
        {
            module: 'NOTIFICATIONS',
            LIST: true,
            DETAILS: false,
            CREATE: true,
            UPDATE: false,
            DELETE: false,
        },
        {
            module: 'ACCOUNT_LEVEL',
            LIST: false,
            DETAILS: false,
            CREATE: false,
            UPDATE: true,
            DELETE: false,
        },
    ]
    const header = [
        {
            label: 'ماژول',
            key: 'module'
        },
        {
            label: 'لیست',
            key: 'LIST',
            value: false,
        },
        {
            label: 'جزییات',
            key: 'DETAILS',
            value: false,
        },
        {
            label: 'ساخت',
            key: 'CREATE',
            value: false,
        },
        {
            label: 'تغییر',
            key: 'UPDATE',
            value: false,
        },
        {
            label: 'حذف',
            key: 'DELETE',
            value: false,
        },
    ]
    const icons = [
        {value: 'ActivityIcon',},
        {value: 'AirplayIcon',},
        {value: 'AnchorIcon',},
        {value: 'ApertureIcon',},
        {value: 'ArchiveIcon',},
        {value: 'AtSignIcon',},
        {value: 'AwardIcon',},
        {value: 'BarChart2Icon',},
        {value: 'BatteryChargingIcon',},
        {value: 'BellIcon',},
        {value: 'BookOpenIcon',},
        {value: 'BriefcaseIcon',},
        {value: 'CalendarIcon',},
        {value: 'CameraIcon',},
        {value: 'CastIcon',},
        {value: 'ClockIcon',},
        {value: 'CodeIcon',},
        {value: 'CoffeeIcon',},
        {value: 'CpuIcon',},
        {value: 'CreditCardIcon',},
        {value: 'DatabaseIcon',},
        {value: 'DivideSquareIcon',},
        {value: 'DivideIcon',},
        {value: 'DollarSignIcon',},
        {value: 'DownloadIcon',},
        {value: 'DribbbleIcon',},
        {value: 'Edit3Icon',},
        {value: 'EditIcon',},
        {value: 'EyeIcon',},
        {value: 'FileTextIcon',},
        {value: 'GlobeIcon',},
        {value: 'HardDriveIcon',},
        {value: 'HomeIcon',},
        {value: 'HeadphonesIcon',},
        {value: 'InstagramIcon',},
        {value: 'LockIcon',},
        {value: 'PhoneCallIcon',},
        {value: 'PieChartIcon',},
        {value: 'PrinterIcon',},
        {value: 'PowerIcon',},
        {value: 'RepeatIcon',},
        {value: 'RssIcon',},
        {value: 'ServerIcon',},
        {value: 'SettingsIcon',},
        {value: 'Share2Icon',},
        {value: 'ShieldIcon',},
        {value: 'ShoppingBagIcon',},
        {value: 'ShoppingCartIcon',},
        {value: 'ToolIcon',},
        {value: 'TrendingUpIcon',},
        {value: 'UserIcon',},
        {value: 'WifiIcon',},
        {value: 'Volume2Icon',},
        {value: 'UnlockIcon',},
        {value: 'ZapIcon',},
    ]
    const variants = [
        {value: 'primary', label: 'آبی پررنگ'},
        {value: 'secondary', label: 'خاکستری'},
        {value: 'success', label: 'سبز'},
        {value: 'warning', label: 'زرد'},
        {value: 'danger', label: 'قرمز'},
        {value: 'info', label: 'آبی روشن'},
    ]
    const status = [
        {variant: 'success', label: 'فعال', value: true},
        {variant: 'danger', label: 'غیر فعال', value: false},
    ]
    const modules = {
        MARKET: 'بازار ها',
        ORDERS: 'سفارش ها',
        TRADES: 'معاملات',
        USERS: 'کاربران سایت',
        ADMINS: 'مدیران',
        ROLES: 'نقش ها',
        DOLLARS: 'مدیریت دلار',
        WALLET: 'کیف پول ها',
        WALLET_ADDRESSES: 'آدرس ها',
        WITHDRAW: 'درخواست های برداشت',
        HISTORIES: 'تاریخچه',
        TRANSACTIONS: 'تراکنش ها',
        EXCHANGEINFO: 'اطلاعات بازار',
        FAQ: 'سوالات متداول',
        USER_MANUAL: 'راهنمای استفاده',
        EXCHANGE_INFO: 'اطلاعات سایت',
        NOTIFICATIONS: 'پیام ها',
        ACCOUNT_LEVEL: 'سطوح کاربری',
    }

    import {
        BCard,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BCardHeader,
        BCardTitle,
        BTable,
        BFormCheckbox,
        BRow,
        BCol,
        BOverlay,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        name: "AdminProfile",
        props: ['id'],
        components: {
            BCard,
            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton,
            vSelect,
            BCardHeader,
            BCardTitle,
            BTable,
            BFormCheckbox,
            BRow,
            BCol,
            BOverlay,
        },
        data() {
            return {
                form: {
                    firstName: '',
                    lastName: '',
                    newPassword: '',
                    email: '',
                    mobileNumber: '',
                    role: 'no-role',
                    isActive: false
                },
                password: '',
                roles: [],
                checked: {},
                privileges: [],
                validation: false,
                permissionsData,
                header,
                icons,
                variants,
                status,
                modules
            }
        },
        computed: {
            roleAccess() {
                const a = {
                    UPDATE: 'اصلاح',
                    LIST: 'لیست',
                    DELETE: 'حذف',
                    CREATE: 'ایجاد',
                    DETAILS: 'جزییات',
                }
                return e => a[e];
            },
            roleLabel() {
                return e => modules[e];
            },
            notValidation() {
                for (let a in this.form) {

                    if (!this.form[a] && ['firstName', 'lastName', 'email', 'mobileNumber', 'role'].includes(a))
                        return true

                }
                return !!(this.form.newPassword && this.form.newPassword !== this.password);

            },
        },
        methods: {
            async Submit() {
                this.state.loading = true

                let id
                if (this.id === undefined) {
                    id = this.form.id
                }else {
                    id = this.id
                }

                await this.$axios.post(`/admins/${id}`, this.form)

                this.$swal({
                    icon: 'success',
                    title: 'اطلاعات مدیر اصلاح شد',
                    confirmButtonText: 'تایید',
                    // text: 'Your file has been deleted.',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })
            },
            async getData() {
                this.state.loading = true
                let address
                if (this.id !== undefined) {
                    address = `/admins/${this.id}`
                } else {
                    address = '/admins/info'
                }

                const res = await this.$axios(address)

                this.form = res.data.baseDTO

                const role = await this.$axios('/roles')
                let allRoles = []
                for (let object of role.data.content) {
                    allRoles.push({
                        id: object.id,
                        label: object.persianName,
                        value: object.englishName
                    })
                }
                this.roles = allRoles

                this.form.role = this.form.role.englishName
            },
            async getPrivileges(e) {
                this.state.loading = true

                function isRole(a) {
                    return a.value === e;
                }

                const a = this.roles.find(isRole).id

                const res = await this.$axios(`/roles/${a}`)

                this.privileges = res.data.baseDTO.privileges
            },
        },
        async created() {
            await this.getData()
            await this.getPrivileges(this.form.role)
        }
    }
</script>

<style scoped>

</style>
